import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import GetInTouch from "../../components/get-in-touch";
import RelatedProducts from "../../components/related-products";

interface Feature {
    title: string;
    description: string;
    viewBox: string;
    icon: string;
}

const TravelConnectorPage = () => {
    const { t } = useTranslation();

    const FEATURES: Feature[] = [
        {
            title: t("Short Time to Market"),
            description: t(
                "DEEP Connector can instanly deploy the solution",
            ),
            viewBox: "0 0 650 700",
            icon:
                "M400 64h-48V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H160V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V160h352v298a6 6 0 0 1-6 6zm-52.849-200.65L198.842 404.519c-4.705 4.667-12.303 4.637-16.971-.068l-75.091-75.699c-4.667-4.705-4.637-12.303.068-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l44.104 44.461 111.072-110.181c4.705-4.667 12.303-4.637 16.971.068l22.536 22.718c4.667 4.705 4.636 12.303-.069 16.97z",
        },
        {
            title: t("Backoffice Web Application"),
            description: t(
                "Booking can be easily searched by using a lightweight web application",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M160 448c-25.6 0-51.2-22.4-64-32-64-44.8-83.2-60.8-96-70.4V480c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32V345.6c-12.8 9.6-32 25.6-96 70.4-12.8 9.6-38.4 32-64 32zm128-192H32c-17.67 0-32 14.33-32 32v16c25.6 19.2 22.4 19.2 115.2 86.4 9.6 6.4 28.8 25.6 44.8 25.6s35.2-19.2 44.8-22.4c92.8-67.2 89.6-67.2 115.2-86.4V288c0-17.67-14.33-32-32-32zm256-96H224c-17.67 0-32 14.33-32 32v32h96c33.21 0 60.59 25.42 63.71 57.82l.29-.22V416h192c17.67 0 32-14.33 32-32V192c0-17.67-14.33-32-32-32zm-32 128h-64v-64h64v64zm-352-96c0-35.29 28.71-64 64-64h224V32c0-17.67-14.33-32-32-32H96C78.33 0 64 14.33 64 32v192h96v-32z",
        },
        {
            title: t("Industry Leading Integrations"),
            description: t(
                "DEEP Travel Connector has several built-in interfaces",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M384 320H256c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM192 32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C211.98 300.98 232.4 288 256 288h.28L192 175.51V128h224V64H192V32zM608 0H480c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32z",
        },
        {
            title: t("Fast Extension of New Providers"),
            description: t(
                "The system is designed to be easily reinforced"
            ),
            viewBox: "0 0 750 800",
            icon:
                "M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z",
        },
        {
            title: t("In/out of the Booking Flow"),
            description: t(
                "We are capable to integrate",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z",
        },
        {
            title: t("Cloud Operations, High SLA, Low Cost"),
            description: t(
                "Operated hundred percent in cloud",
            ),
            viewBox: "0 0 750 800",
            icon:
                "M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4z",
        },
    ];

    const getInTouchArray: string[] = [
        t(
            "Would you like to integrate third party ancillaries in your website without months of in-house development?"
        ),
        t(
            "Are you running an online travel business and would like to extend the services with additional travel components?"
        ),
        t("Are you looking for a managed solution with no investment cost to build up new revenue streams?"),
    ];

    const data = useStaticQuery(graphql`
		query TravelConnectorPageQuery {
			allFile(filter: { relativePath: { in: ["banner/product_deep_connector.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

    const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;

    return (
        <Layout>
            <SEO title="Products" />
            <div className="text-brand-grayt">
                {/* Banner */}
                <BackgroundImage Tag="div" fluid={fluidBanner}>
                    <div className="container text-white h-400px sm:pt-16">
                        <div className="md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 highlight">
                            <h1 className="pt-12 text-3xl sm:text-4xl leading-tight font-bold">
                                <div>
                                    <Trans>DEEP</Trans>
                                </div>
                                <span>
                                    <Trans>Travel Connector</Trans>
                                </span>
                            </h1>
                            <p className="text-lg mt-5 pb-24">
                                <span>
                                    <Trans i18nKey="We believe in ready-to-use software products">
                                        We believe in ready-to-use software products combined with enterprise grade
                                        custom development service. That’s why we are keen to build convenient software
                                        solutions for our airline, tour operator, OTA partners.
									</Trans>
                                </span>
                            </p>
                        </div>
                    </div>
                </BackgroundImage>
                {/* Intro */}
                <div id="intro">
                    <div className="container p-4 pt-8">
                        <h1 className="font-bold text-2xl pb-6">
                            <Trans>Seamless Integration of Travel Ancillary Components</Trans>
                        </h1>
                        <p className="text-lg pb-4">
                            <Trans i18nKey="DEEP Travel Connector is a set">
                                DEEP Travel Connector is a set of universal component models that has been created with
                                the mindset to fit into any booking website and to be easily extended with any
                                third-party service provider for supporting fast implementation and flexible
                                reinforcements.
							</Trans>
                        </p>

                        <p className="text-lg pb-4">
                            <Trans i18nKey="it runs in the cloud managed">
                                It runs in the cloud and managed by us which provides extreme high availability and
                                stability for the service.
							</Trans>
                        </p>

                        <p className="text-lg p-4 pb-4 mt-2 mb-4 bg-brand-gray4 inline-block">
                            <q>
                                <Trans i18nKey="It is optimized for high traffic">
                                    It is optimized for high traffic, ensuring low operational cost and seamless website
                                    integration
								</Trans>
                            </q>
                        </p>

                        <p className="text-lg pb-4">
                            <Trans i18nKey="DEEP Travel Connector is developed from the ground">
                                DEEP Travel Connector is developed from the ground up using latest technologies to keep
                                it lightweight and flexible. Our team has learned from the market and designed the
                                product to meet the needs of changing business demands.
							</Trans>
                        </p>
                    </div>
                </div>
            </div>
            {/* Features */}
            <div id="features" className="bg-brand-gray4 pb-8">
                <div className="container p-4 pt-8">
                    <h1 className="uppercase font-bold text-2xl mb-10">
                        <span className="text-brand-green mr-2">//</span>
                        <Trans>Features</Trans>
                    </h1>
                    <div className="flex flex-wrap text-brand-grayt">
                        {FEATURES.map((feature) => {
                            return (
                                <div className="w-full sm:w-1/2 flex" key={feature.title}>
                                    <div className="mt-2 mr-2">
                                        <svg
                                            className="w-14 fill-current"
                                            viewBox={feature.viewBox}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d={feature.icon} />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className="font-semibold text-xl">{feature.title}</div>
                                        <div className="mb-4 mt-2 mr-4 text-lg">{feature.description}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <GetInTouch getInTouchArray={getInTouchArray} />
            <RelatedProducts missing="deep-travel-connector" />
        </Layout>
    );
};

export default TravelConnectorPage;
